import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { PtrWithWindows, Transformation } from 'domain/model.types';
import { ColAddr, Trait, TraitUsage } from 'domain/traits.types';

import { Ptr } from 'services/cohort/cohort.types.api';
import { AlgorithmName } from 'services/intervention/intervention.types';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';

import CFLoadWrapper from 'components/CFLoadWrapper';
import FeaturePicker, {
  FeaturePickerExtraType,
  FeaturePickerRef,
  FeaturePickerTypes,
} from '../components/FeaturePicker';

import { Steps, useModelContext } from '../../useContext';

import './features.scss';

interface Props {}

export interface CensoringModelFeaturesRef {
  value: () => {
    dynamic_features?: PtrWithWindows[];
    static_features?: ColAddr[];
    transformations?: Record<Ptr, Transformation>;
  };
}

const CensoringModelFeatures = forwardRef<CensoringModelFeaturesRef, Props>(function CensoringModelFeatures(
  {}: Props,
  ref
) {
  const { updateReadiness, definition } = useModelContext();

  const [isReady, setIsReady] = useState(false);

  const featuresPickerRef = useRef<FeaturePickerRef>() as React.MutableRefObject<FeaturePickerRef>;

  useImperativeHandle(ref, () => ({
    value() {
      console.log('[transformation] ', featuresPickerRef.current.transformations);
      return {
        static_features: featuresPickerRef.current.static,
        dynamic_features: featuresPickerRef.current.dynamic, // always, even with windows, to be removed if not necessary in the main components
        transformations:
          definition?.algorithm === AlgorithmName.DeepRecurrentSurvivalMachines
            ? featuresPickerRef.current.transformations
            : undefined,
      };
    },
  }));

  const handleFeaturesChanged = useCallback((staticTraits: Trait[], dynamicTraits: Trait[]) => {
    const isFeatureReady = staticTraits.length !== 0 && dynamicTraits.length !== 0;
    updateReadiness(isFeatureReady, Steps.Features);
  }, []);

  return (
    <CFLoadWrapper isLoading={!isReady}>
      <InterventionSection name={Steps.Features} title={'Features'} subtitle="Add features to your model">
        <FeaturePicker
          ref={featuresPickerRef}
          onReady={() => setIsReady(true)}
          onChange={handleFeaturesChanged}
          usage={TraitUsage.CensoringMetric}
          types={[FeaturePickerTypes.Static, FeaturePickerTypes.Dynamic]}
          extraType={
            definition?.algorithm === AlgorithmName.DeepRecurrentSurvivalMachines
              ? FeaturePickerExtraType.Tranformation
              : FeaturePickerExtraType.Window
          }
        />
      </InterventionSection>
    </CFLoadWrapper>
  );
});

export default CensoringModelFeatures;
