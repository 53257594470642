import React, { useCallback, useEffect, useState } from 'react';

import InterventionSection from 'views/intervention/interventions/NewIntervention/interventionSection';
import TraitListItem from 'views/model/components/TraitsListItem';

import { AlgorithmClass } from 'services/intervention/intervention.types';

import CFTitledComponent, { TitleSize } from 'components/CFTitledComponent';
import CFLoadWrapper from 'components/CFLoadWrapper';
import CFTabs from 'components/CFTabs';
import CFTitledSection from 'components/CFTitledSection';
import TraitsTable from 'connected-components/TraitsTable';

import { createTraitCode } from 'services/traits/helpers.traits';

import { Trait, TraitSubject, TraitUsage } from 'domain/traits.types';

import { useServicesContext } from 'hooks/useServicesContext';

import { Steps, useModelContext } from '../../useContext';

import './features.scss';

const ModelFeatures = (): JSX.Element => {
  const { traitSessionService: traitService } = useServicesContext();
  const { setFeatures, updateReadiness, definition, setWindows, windows } = useModelContext();

  const [isReady, setIsReady] = useState(false);
  const [dynamicTraits, setDynamicTraits] = useState<Trait[]>([]);
  const [staticTraits, setStaticTraits] = useState<Trait[]>([]);
  const [selectedDynamic, setSelectedDynamic] = useState<Trait[]>([]);
  const [selectedStatic, setSelectedStatic] = useState<Trait[]>([]);

  useEffect(() => {
    const isReady = selectedDynamic.length !== 0 || selectedStatic.length !== 0;

    if (isReady) {
      setFeatures({
        dynamic: selectedDynamic.map((trait) => trait.addr),
        static: selectedStatic.map((trait) => trait.addr),
      });
    }

    updateReadiness(isReady, Steps.Features);
  }, [selectedDynamic, selectedStatic]);

  const fetchTraits = async () => {
    setIsReady(false);

    const usage = definition?.class === AlgorithmClass.Censoring ? TraitUsage.CensoringMetric : TraitUsage.BanditMetric;
    setDynamicTraits(await traitService.getContextDynamicTraits(TraitSubject.User, usage));
    setStaticTraits(await traitService.getBanditContextStaticTraits(TraitSubject.User));
    setIsReady(true);
  };

  useEffect(() => {
    fetchTraits();
  }, []);

  const handleSelectDynamic = (trait: Trait, selected: boolean, traitWindows?: number[]) => {
    let newSelectedDynamic: Trait[] = [];

    if (!selected) {
      newSelectedDynamic = selectedDynamic.filter((_trait) => createTraitCode(_trait) !== createTraitCode(trait));
    } else {
      if (!selectedDynamic.find((item) => item.addr.ptr === trait.addr.ptr)) {
        newSelectedDynamic = [...selectedDynamic, trait];
      } else {
        newSelectedDynamic = [...selectedDynamic];
      }
    }

    if (traitWindows) {
      setWindows((windows) => ({ ...windows, [trait.addr.ptr]: traitWindows }));
    }

    setSelectedDynamic(newSelectedDynamic);
  };

  const handleSelectStatic = useCallback(
    (trait: Trait) => {
      const newSelectedStatic = selectedStatic.filter((_trait) => createTraitCode(_trait) !== createTraitCode(trait));

      if (newSelectedStatic.length === selectedStatic.length) {
        newSelectedStatic.push(trait);
      }

      setSelectedStatic(newSelectedStatic);
    },
    [selectedStatic]
  );

  return (
    <CFLoadWrapper isLoading={!isReady}>
      <InterventionSection name={Steps.Features} title={'Features'} subtitle="Add features to your model">
        <div className="model-features">
          <CFTabs.TabContext value="dynamic">
            <CFTabs.Tabs>
              <CFTabs.Tab value="dynamic">Dynamic Features</CFTabs.Tab>
              <CFTabs.Tab value="static">Static Features</CFTabs.Tab>
            </CFTabs.Tabs>

            <CFTabs.TabPanel value="dynamic" className="metrics-policy-tabpanel">
              <TraitsTable
                selected={selectedDynamic}
                handleSelectTrait={(row, selected, windows) => handleSelectDynamic(row as Trait, selected, windows)}
                traits={dynamicTraits}
                showWindow={definition?.class === AlgorithmClass.Censoring}
              />
            </CFTabs.TabPanel>

            <CFTabs.TabPanel value="static" className="metrics-policy-tabpanel">
              <TraitsTable
                selected={selectedStatic}
                handleSelectTrait={(row) => handleSelectStatic(row as Trait)}
                traits={staticTraits}
              />
            </CFTabs.TabPanel>
          </CFTabs.TabContext>
          <CFTitledSection nested={true} title={`Selected Data (${selectedDynamic.length + selectedStatic.length})`}>
            <div className="selected-metrics-section">
              <CFTitledComponent title="Dynamic Features" size={TitleSize.Big} className={'trait-list'}>
                {selectedDynamic.length ? (
                  selectedDynamic.map((trait) => (
                    <TraitListItem
                      key={createTraitCode(trait)}
                      trait={trait}
                      onDelete={(trait) => handleSelectDynamic(trait, false)}
                      windows={windows[trait.addr.ptr]}
                    />
                  ))
                ) : (
                  <div className="empty-label">No Selected Dynamic Features</div>
                )}
              </CFTitledComponent>

              <CFTitledComponent title="Static Features" size={TitleSize.Big} className={'trait-list'}>
                {selectedStatic.length ? (
                  selectedStatic.map((trait) => (
                    <TraitListItem
                      key={createTraitCode(trait)}
                      trait={trait}
                      onDelete={(trait) => handleSelectStatic(trait)}
                    />
                  ))
                ) : (
                  <div className="empty-label">No Selected Static Features</div>
                )}
              </CFTitledComponent>
            </div>
          </CFTitledSection>
        </div>
      </InterventionSection>
    </CFLoadWrapper>
  );
};

export default ModelFeatures;
