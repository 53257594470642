import React from 'react';

import { getDurationFromNanos, Granularity } from 'helpers/dates';

import { ColAddr } from 'domain/traits.types';
import { AlgorithmClass, Model, PtrWithWindows } from 'domain/model.types';

import { getIdentifierFromAddr, getTraitNameFromAddr } from 'services/traits/helpers.traits';

import CFTraitItem from 'connected-components/traits/CFTraitItem';
import CFWindowsInfo from 'connected-components/traits/CFWindowsInfo';

import { AlgorithmName } from 'services/intervention/intervention.types';

import './features.scss';

interface Props {
  model: Model;
}

const CensoringDynamicFeatures = ({ ptrWindows }: { ptrWindows: PtrWithWindows[] }) => {
  return (
    <>
      {ptrWindows.map((ptrWindow) => {
        return (
          <div key={ptrWindow.ptr} className="trait-with-info">
            <CFTraitItem key={ptrWindow.ptr} addr={ptrWindow.ptr} />
            <CFWindowsInfo ptr={ptrWindow.ptr} windows={ptrWindow.windows} />
          </div>
        );
      })}
    </>
  );
};

const RDSMDynamicFeatures = ({ model }: { model: Model }) => {
  if (!model.definition.data_defn.dynamic_features) {
    return null;
  }

  return (
    <>
      {model.definition.data_defn.dynamic_features?.map((feat) => {
        const censoring = model.definition.data_defn.censoring;
        const transformations = censoring?.transformations;
        const transformation = transformations ? transformations[feat.ptr] : undefined;

        return (
          <div key={feat.ptr} className="trait-with-info">
            <CFTraitItem key={feat.ptr} addr={feat.ptr} />
            <span> ({(transformation || '').toUpperCase()}) </span>
          </div>
        );
      })}
    </>
  );
};

const DynamicFeatures = ({ addrs }: { addrs: ColAddr[] }) => {
  return (
    <>
      {addrs.map((addr) => (
        <CFTraitItem key={getIdentifierFromAddr(addr)} addr={addr} />
      ))}
    </>
  );
};

const ModelFeatures = ({ model }: Props) => {
  const modelClass = model.definition.algo_spec.class_name;
  const algorithm = model.definition.algo_spec.algo_name;

  const dynamicFeaturesLength = (
    model.definition.data_defn.dynamic_features ||
    model.definition.data_defn.censoring?.dynamic_features ||
    []
  ).length;

  const staticFeatures = model.definition.data_defn.static_features || [];

  return (
    <div className="model-definition-features">
      {modelClass === AlgorithmClass.Recommender && (
        <>
          <DynamicFeatures addrs={model.definition.data_defn.recommender?.embedding_traits as ColAddr[]} />
          <div className="field-value">
            <span>Test duration:</span>
            {getDurationFromNanos(model.definition.data_defn.recommender?.test_dur || 0, Granularity.Day)} days
          </div>
          <div className="field-value">
            <span>Full duration:</span>
            {getDurationFromNanos(model.definition.data_defn.recommender?.full_dur || 0, Granularity.Day)} days
          </div>
        </>
      )}

      {dynamicFeaturesLength === 0 && staticFeatures.length === 0 && modelClass !== AlgorithmClass.Recommender && (
        <div className="model-definition-parameters no-params">No features</div>
      )}

      {dynamicFeaturesLength !== 0 && (
        <div className="model-features-selected">
          <div className="title">Dynamic Features</div>
          <div className="selected-items">
            {modelClass === AlgorithmClass.Censoring && algorithm !== AlgorithmName.DeepRecurrentSurvivalMachines && (
              <CensoringDynamicFeatures ptrWindows={model.definition.data_defn.censoring?.dynamic_features || []} />
            )}

            {modelClass === AlgorithmClass.Censoring && algorithm === AlgorithmName.DeepRecurrentSurvivalMachines && (
              <RDSMDynamicFeatures model={model} />
            )}

            {modelClass !== AlgorithmClass.Censoring && modelClass !== AlgorithmClass.Recommender && (
              <DynamicFeatures addrs={model.definition.data_defn.dynamic_features || []} />
            )}
          </div>
        </div>
      )}
      {staticFeatures.length !== 0 && (
        <div className="model-features-selected">
          <div className="title">Static Features</div>
          <div className="selected-items">
            {staticFeatures.map((addr) => (
              <CFTraitItem key={getTraitNameFromAddr(addr)} addr={addr} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelFeatures;
