import React, { useEffect, useState } from 'react';

import { CFRoutes } from 'routes';

import useCFNavigation from 'hooks/useCFNavigation';
import { useServicesContext } from 'hooks/useServicesContext';

import { AlgorithmClass } from 'domain/model.types';

import useURLModel from 'views/model/hooks/useURLModel';

import { remove as removeModel } from 'services/model/model.repo';
import { AuthAction, isAllowedTo } from 'services/authorization.service';
import Cohort from 'services/cohort/domain/Cohort';

import Schedule from 'connected-components/schedule';

import { ToastType } from 'components/CFToast/types';
import ModelDetails from '../../components/ModelDetails';
import CFConfirmableButton from 'components/CFConfirmableButton';
import CFTrashButton from 'components/buttons/CFTrashButton';
import CFSpinner from 'components/CFSpinner';

import { useToast } from 'hooks';

import MonitoringSection from './monitoringSection';
import ModelParameters from './parameters';
import ModelFeatures from './features';
import ModelRuns from './runs';
import ModelCohort from './cohort';
import ModelTarget from './target';

import { Tabs } from '../../tabs';

import ModelDetailLayout from '../layout';

import colors from 'common.scss';

import './model-definition.scss';

const ModelDefinition = () => {
  const navigate = useCFNavigation();
  const { cohortService } = useServicesContext();

  const { addToast } = useToast();

  const model = useURLModel();

  const [userCohort, setUserCohort] = useState<Cohort>();

  useEffect(() => {
    (async () => {
      if (!model) {
        return;
      }

      if (model.definition.algo_spec.class_name !== AlgorithmClass.Recommender) {
        return;
      }

      if (!model.definition.data_defn.recommender) {
        return;
      }

      if (model.definition.algo_spec.class_name === AlgorithmClass.Recommender) {
        const userCohort = await cohortService.getRemoteCohort(model.definition.data_defn.recommender.user_cohort_id);

        setUserCohort(userCohort);
      }
    })();
  }, [model]);

  const handleRemoveModel = () => {
    if (!model?.definition?.id) {
      return;
    }

    removeModel(model.definition.id)
      .then(() => {
        navigate(CFRoutes.model);
        addToast(`Model deleted`, ToastType.SUCCESS);
      })
      .catch((err) => {
        addToast(`Error deleting model`, ToastType.ERROR);
        console.log('Error deleting model: ', err);
      });
  };

  const modelActions = (
    <div className="actions">
      {isAllowedTo(AuthAction.CreateModel) && model?.definition?.purpose !== 'inv' && (
        <CFConfirmableButton title={'Remove model'} question={'Are you sure to remove this model?'}>
          <CFTrashButton onClick={() => handleRemoveModel()} />
        </CFConfirmableButton>
      )}
    </div>
  );

  return (
    <ModelDetailLayout className="model-monitor-definition" tab={Tabs.Definition}>
      {!model && (
        <div>
          <CFSpinner size={70} color={colors.cfCyan} stroke={4} />
        </div>
      )}

      {model && (
        <>
          <div className="model-definition-container">
            <MonitoringSection title={`Model #${model.definition.id}`} actionSection={modelActions}>
              <ModelDetails model={model} />
            </MonitoringSection>

            <MonitoringSection title="Parameters">
              <ModelParameters model={model} />
            </MonitoringSection>

            {model.definition.algo_spec.class_name === AlgorithmClass.Recommender ? (
              <>
                <MonitoringSection title="User Cohort">
                  <ModelCohort model={model} cohort={userCohort} />
                </MonitoringSection>

                <MonitoringSection title="Item Cohort">
                  <ModelCohort
                    model={model}
                    cohort={new Cohort(model.cohort)}
                    splitRatio={model.definition.population_policy.split_ratio}
                  />
                </MonitoringSection>
              </>
            ) : (
              <>
                <MonitoringSection title="Cohort">
                  <ModelCohort
                    model={model}
                    cohort={new Cohort(model.cohort)}
                    splitRatio={model.definition.population_policy.split_ratio}
                  />
                </MonitoringSection>
              </>
            )}

            <MonitoringSection title="Features">
              <ModelFeatures model={model} />
            </MonitoringSection>

            <MonitoringSection title="Target">
              <ModelTarget model={model} />
            </MonitoringSection>

            <MonitoringSection title="Runs">
              <ModelRuns model={model} />
            </MonitoringSection>

            <Schedule id={model.schedule.id} label="model" />
          </div>
        </>
      )}
    </ModelDetailLayout>
  );
};

export default ModelDefinition;
