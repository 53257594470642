import { Modules } from 'domain/general.types';
import { UserRole } from 'services/admin/users/user.types';
import { getCurrentSection, Sections } from 'routes';
import { getOrganization, getProject, getUserInfo, isIncognitoMode } from 'services/session/session.service';
import { UserDetailedInfo } from 'types';

export enum AuthAction {
  AddViewerRole,
  AdoptNudge,
  CreateCohort,
  DeleteCohort,
  CreateNudge,
  CreateIntervention,
  CreateOrganization,
  ViewAdmin,
  ViewTraitDefinitions,
  Backfill,
  SeeIntervention,
  SeeModel,
  SeeUnpublishedModel,
  CreateModel,
  ModelInspectMLTraits,
  PinModel,
  ScheduleMinutes,
  SeeExtendedInterventionError,
  SeeAnalyticsUserEngagement,
  SeeAssistant,
  SeeAssistantMetadata,
  SeeAssistantEmbedded,
  UpdateLandingCohort,
  SeeDevFeatures,
  SynchronizeView,
  SynchronizeRun,
  SetIncognitoMode,
  SeeInternalAdminTab,
  EditAdminRoles,
  DeleteUser,
}

const CF_DOMAIN = 'causalfoundry.ai';

enum AdminRoles {
  TraitAdmin = 'trait_admin',
  ModelAdmin = 'model_admin',
  ModelViewer = 'model_viewer',
}

export interface CFAuthService {
  isAllowedTo: (authAction: AuthAction) => boolean;
}

const isOrgAdmin = (orgId: string, userInfo: UserDetailedInfo) => {
  if (orgId === '') {
    return false;
  }

  return userInfo.user.roles[orgId].org_roles.includes(UserRole.Admin);
};

const isSuperUser = (userInfo: UserDetailedInfo) => {
  if (!userInfo.user) {
    return false;
  }

  return userInfo.user.superuser;
};

/* 
const isSuperUser = (userInfo: UserDetailedInfo, byPassIncognito = true) => {
  if (!userInfo.user) {
    return false;
  }

  if (byPassIncognito) {
    if (isIncognitoMode()) {
      return false;
    } else {
      return userInfo.user.superuser;
    }
  } else {
    return userInfo.user.superuser;
  }
};
*/

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isLocalDevelopment = () => {
  if (isIncognitoMode()) {
    return false;
  } else {
    return window.location.hostname.includes('localhost');
  }
};

const isCFUser = (userInfo: UserDetailedInfo) => {
  if (isIncognitoMode()) {
    return false;
  } else {
    return userInfo.user.username.endsWith(CF_DOMAIN);
  }
};

const isAllowedToBackfill = () => {
  // superuser or organization admin
  const userInfo = getUserInfo();
  const orgId = getOrganization() || '';

  if (!userInfo.user) {
    return false;
  }

  return isSuperUser(userInfo) || isOrgAdmin(orgId, userInfo);
};

const isAllowedToViewAdmin = () => {
  // superuser or organization admin
  const userInfo = getUserInfo();
  const orgId = getOrganization() || '';

  if (!userInfo.user) {
    return false;
  }

  return isSuperUser(userInfo) || isOrgAdmin(orgId, userInfo);
};

const isAllowedToSeeIntervention = () => {
  return true;
};

const isAllowToCreateOrganization = () => {
  const userInfo = getUserInfo();

  return isSuperUser(userInfo);
};

const isAllowToAddOrEditViewerRole = () => {
  const userInfo = getUserInfo();
  const orgId = getOrganization() || '';

  return isSuperUser(userInfo) || isOrgAdmin(orgId, userInfo);
};

const isAllowedToCreateCohort = () => {
  const userInfo = getUserInfo();
  const orgId = getOrganization() || '';

  if (isSuperUser(userInfo) || isOrgAdmin(orgId, userInfo)) {
    return true;
  } else {
    return false;
  }
};

const isAllowedToCreateIntervention = () => {
  const userInfo = getUserInfo();

  const allowedEmails = ['nikkiplyem@gmail.com'];

  if (isSuperUser(userInfo) || isCFUser(userInfo) || allowedEmails.includes(userInfo.user.username)) {
    return true;
  }

  return false;
};

const isAllowedToCreateNudge = () => {
  const userInfo = getUserInfo();

  if (isSuperUser(userInfo)) {
    return true;
  }

  return false;
};

const isAllowedToViewTraitDefinitions = () => {
  const userInfo = getUserInfo();

  return isSuperUser(userInfo);
};

const isAllowedToAdoptNudge = () => {
  const userInfo = getUserInfo();

  return isSuperUser(userInfo);
};

const isAllowedToSeeUnpublishedModel = () => {
  const userInfo = getUserInfo();

  return isSuperUser(userInfo) || isLocalDevelopment();
};

const isAllowedToSeeModel = () => {
  return true;
};

const isAllowedToCreateModel = () => {
  const userInfo = getUserInfo();

  const allowedEmails = ['nikkiplyem@gmail.com'];

  return isSuperUser(userInfo) || isCFUser(userInfo) || allowedEmails.includes(userInfo.user.username);
};

const isAllowedToPinModel = () => {
  const userInfo = getUserInfo();

  return isSuperUser(userInfo);
};

const isAllowedToScheduleMinutes = () => {
  const userInfo = getUserInfo();
  const orgId = getOrganization() || '';

  return isOrgAdmin(orgId, userInfo) || isCFUser(userInfo) || isSuperUser(userInfo);
};

const isAllowedToSeeExtendedInterventionError = () => {
  const userInfo = getUserInfo();
  return isCFUser(userInfo) || isSuperUser(userInfo);
};

const isAllowedToInspectMlTraits = () => {
  const userInfo = getUserInfo();
  return isSuperUser(userInfo);
};

const isAllowedToSeeUserEngagement = () => {
  const userInfo = getUserInfo();
  const orgId = getOrganization();
  const projId = getProject();

  const orgIndex = userInfo.available_orgprojs.findIndex((orgproj) => `${orgproj.org.id}` === orgId);

  if (orgIndex === undefined) {
    return false;
  }

  const proj = userInfo.available_orgprojs[orgIndex].projs.find((proj) => `${proj.id}` === projId);

  return proj?.modules.includes(Modules.PatientMgmt) || false;
};

const isAllowedToSeeAssistant = () => {
  const userInfo = getUserInfo();
  return isSuperUser(userInfo) || isCFUser(userInfo);
};

const isAllowedToSeeAssistantMetadata = () => {
  const userInfo = getUserInfo();
  return isSuperUser(userInfo) || isCFUser(userInfo);
};

const isAllowedToSeeAssistantEmbedded = () => {
  const userInfo = getUserInfo();

  return (isSuperUser(userInfo) || isCFUser(userInfo)) && getCurrentSection() !== Sections.Assistant;
};

const isAllowedToUpdateLandingCohort = () => {
  const userInfo = getUserInfo();
  const orgId = getOrganization() || '';

  return isOrgAdmin(orgId, userInfo) || isSuperUser(userInfo);
};

const isAllowedToSeeDevFeatures = () => {
  return isLocalDevelopment();
};

const isAllowedToSeeSynchronizationStatus = () => {
  const userInfo = getUserInfo();

  return isSuperUser(userInfo);
};

const isAllowedToSyncronized = () => {
  const userInfo = getUserInfo();
  const allowedEmails = ['dexian@causalfoundry.ai', 'aditya@causalfoundry.ai'];

  return allowedEmails.includes(userInfo.user.username);
};

const isAllowedToSetIncognitoMode = () => {
  const userInfo = getUserInfo();

  return isSuperUser(userInfo) || isCFUser(userInfo);
};

const isAllowedToSeeInternalAdmintab = () => {
  const userInfo = getUserInfo();

  return userInfo.user.admin_roles.includes(AdminRoles.TraitAdmin) || isLocalDevelopment();
};

const isAllowedToEditAdminRoles = () => {
  const userInfo = getUserInfo();

  return isCFUser(userInfo) || isSuperUser(userInfo);
};

const isAllowedToRemoveUserFromPlatform = () => {
  const userInfo = getUserInfo();

  return isSuperUser(userInfo);
};

export const isAllowedTo = (authAction: AuthAction) => {
  if (authAction === AuthAction.ViewAdmin) {
    return isAllowedToViewAdmin();
  } else if (authAction === AuthAction.Backfill) {
    return isAllowedToBackfill();
  } else if (authAction === AuthAction.SeeIntervention) {
    return isAllowedToSeeIntervention();
  } else if (authAction === AuthAction.CreateOrganization) {
    return isAllowToCreateOrganization();
  } else if (authAction === AuthAction.AddViewerRole) {
    return isAllowToAddOrEditViewerRole();
  } else if (authAction === AuthAction.CreateCohort) {
    return isAllowedToCreateCohort();
  } else if (authAction === AuthAction.CreateIntervention) {
    return isAllowedToCreateIntervention();
  } else if (authAction === AuthAction.CreateNudge) {
    return isAllowedToCreateNudge();
  } else if (authAction === AuthAction.ViewTraitDefinitions) {
    return isAllowedToViewTraitDefinitions();
  } else if (authAction === AuthAction.AdoptNudge) {
    return isAllowedToAdoptNudge();
  } else if (authAction === AuthAction.SeeModel) {
    return isAllowedToSeeModel();
  } else if (authAction === AuthAction.CreateModel) {
    return isAllowedToCreateModel();
  } else if (authAction === AuthAction.PinModel) {
    return isAllowedToPinModel();
  } else if (authAction === AuthAction.ScheduleMinutes) {
    return isAllowedToScheduleMinutes();
  } else if (authAction === AuthAction.SeeExtendedInterventionError) {
    return isAllowedToSeeExtendedInterventionError();
  } else if (authAction === AuthAction.ModelInspectMLTraits) {
    return isAllowedToInspectMlTraits();
  } else if (authAction === AuthAction.SeeAnalyticsUserEngagement) {
    return isAllowedToSeeUserEngagement();
  } else if (authAction === AuthAction.SeeAssistant) {
    return isAllowedToSeeAssistant();
  } else if (authAction === AuthAction.SeeAssistantMetadata) {
    return isAllowedToSeeAssistantMetadata();
  } else if (authAction === AuthAction.SeeAssistantEmbedded) {
    return isAllowedToSeeAssistantEmbedded();
  } else if (authAction === AuthAction.UpdateLandingCohort) {
    return isAllowedToUpdateLandingCohort();
  } else if (authAction === AuthAction.SeeDevFeatures) {
    return isAllowedToSeeDevFeatures();
  } else if (authAction === AuthAction.SynchronizeView) {
    return isAllowedToSeeSynchronizationStatus();
  } else if (authAction === AuthAction.SynchronizeRun) {
    return isAllowedToSyncronized();
  } else if (authAction === AuthAction.SetIncognitoMode) {
    return isAllowedToSetIncognitoMode();
  } else if (authAction === AuthAction.SeeInternalAdminTab) {
    return isAllowedToSeeInternalAdmintab();
  } else if (authAction === AuthAction.EditAdminRoles) {
    return isAllowedToEditAdminRoles();
  } else if (authAction === AuthAction.SeeUnpublishedModel) {
    return isAllowedToSeeUnpublishedModel();
  } else if (authAction === AuthAction.DeleteCohort) {
    return isAllowedToCreateCohort();
  } else if (authAction === AuthAction.DeleteUser) {
    return isAllowedToRemoveUserFromPlatform();
  }

  return false;
};

export default {
  isAllowedTo,
};
