import Cohort from 'services/cohort/domain/Cohort';
import { CohortAPI } from '../cohort/cohort.types';
import { FilterAPI } from '../cohort/cohort.types.api';

import { TimeRFC3999 } from '../../domain/general.types';
import { SplitRatio } from '../../domain/model.types';
import { Nudge } from '../nudging/nudge.types';
import { ColAddr } from '../../domain/traits.types';
import { MarkovProcessId } from 'services/markov/markov.types';
import { EdgeEdit, MarkovNodeAPI } from 'services/markov/markov.types.api';
import { Schedule } from 'services/scheduling/schedulting.types.api';

export enum ParticipantType {
  Static = 'static',
  Dynamic = 'dynamic',
}

export interface StaticSamplePolicy {
  sample_id: string; // sample id from POST /generate-static-sample
  pure_control_sample_id?: string;
  pure_control_extra_filter?: FilterAPI[];
  extra_filters: FilterAPI[];
}

export interface ParticipantPolicy {
  type: ParticipantType;
  static_sample: StaticSamplePolicy;
  cohort_id: number;
}

export type GroupAllocation = Record<string, number>;

export interface StartEndPair {
  start: string;
  dur: number;
  end?: string;
}

export enum NudgeScheduleType {
  NudgeScheduleFixedType = 'fixed',
  NudgeScheduleEventBasedType = 'event_based',
}

export interface FixedSchedulePolicy {
  schedule_id: number;
}

export interface EventBasedSchedulePolicy {
  end_date: string;
}

export interface NudgeSchedule {
  type: NudgeScheduleType;
  fixed_schedule?: FixedSchedulePolicy;
  event_based?: EventBasedSchedulePolicy;
}

export interface NudgePolicy {
  schedule: NudgeSchedule;
  expire_dur: number;
  group_allocation: GroupAllocation;
}

export interface BanditMetrics {
  objective: ColAddr;
  supporting: ColAddr[];
  decision_time_window: number;

  static_ctx: ColAddr[];
  dynamic_ctx: ColAddr[];

  static_ctx_id?: string;
}

export interface ABMetrics {
  metrics: ColAddr[];
}

export interface RestlessMetrics {
  metrics: ColAddr[];
}

export interface MetricPolicy {
  bandit?: BanditMetrics;
  ab?: ABMetrics;
  restless?: RestlessMetrics;
}

export interface ContextPolicy {
  static_context: ColAddr[];
  dynamic_context: ColAddr[];
}

export enum AlgorithmType {
  Bandit = 'bandit',
  ABTest = 'ab_testing',
  RestlessBandit = 'restless-bandit',
}

export enum AlgorithmName {
  LinUCB = 'LinUCB',
  LinTS = 'LinTS',
  SubspaceEKFMLPBandit = 'SubspaceEKFMLPBandit',
  ExploreThenCommit = 'Explore-Then-Commit',
  LiM2 = 'LiM2',
  LiM2UCB = 'LiM2UCB',
  UCBBandit = 'UCB-Bandit',
  GGLinearMABTS = 'GG-LinearMAB-TS',
  GGLinearMABUCB = 'GG-LinearMAB-UCB',
  Item2Item = 'item2item',
  Item2Vec = 'item2vec',
  DeepRecurrentSurvivalMachines = 'DeepRecurrentSurvivalMachines',
}

export enum AlgorithmClass {
  Bandit = 'bandit',
  RestlessBandit = 'restless-bandit',
  Censoring = 'censoring',
  Forecasting = 'forecasting',
  Recommender = 'recommender',
}

export interface MarkovPolicy {
  id: MarkovProcessId;
  budget: number;
  nodes: MarkovNodeAPI[];
  edits: Record<GroupName, EdgeEdit[]>;
  offset: number;
  population_blend: number;
  gap: number;
}

export interface AlgorithmSpec {
  class_name?: AlgorithmClass;
  algo_name?: AlgorithmName;
  class_property?: Record<string, any>;
  markov?: MarkovPolicy;
  social_group_filters?: ColAddr[];
}

export type AlgorithmPolicy = {
  type: AlgorithmType;
  spec?: AlgorithmSpec;
  model_def_id?: number;
  split_ratio?: SplitRatio;
};

export interface CreatingIntervention {
  name: string;
  description: string;

  participant_policy: ParticipantPolicy;
  nudge_policy: NudgePolicy;
  metric_policy: MetricPolicy;
  algo_policy: AlgorithmPolicy;
}

export interface Intervention extends CreatingIntervention {
  id: InterventionId;
  pid: number;
  terminated: boolean;
  created_by: string;
  created_at: string;
}

export type GroupName = string;

export enum InterventionStatus {
  Finished = 'finished',
  Running = 'running',
  Terminated = 'terminated',
  Paused = 'paused',
  Pending = 'pending',
}

export enum InterventionSlotStatus {
  Success = 'success',
  InProgress = 'in progress',
  Failed = 'failed',
  Invalid = 'invalid',
  Timeout = 'timeout',
}

export interface NewInterventionRequest {
  intervention: CreatingIntervention;
  schedule: Schedule;
  draft_id?: number;
}

export interface InterventionDraft {
  ID: number;
  CreatedBy: string;
  CreatedAt: TimeRFC3999;
  Draft: Required<Pick<NewInterventionRequest, 'draft_id'>> & Omit<NewInterventionRequest, 'draft_id'>;
}

export interface InterventionViewAPI {
  intervention: Intervention;
  cohort: CohortAPI;
  status: InterventionStatus;
  schedule: Schedule;
  nudge_group_allocation: Record<string, Nudge>;
  latest_slot: {
    detail: string;
    status: string;
    update_at: TimeRFC3999;
  };
}

export interface InterventionFailedStatus {
  detail: {
    type: string;
    msg: string;
    loc: string;
  };
}

// TODO --> rename to AppInterventionView
export interface InterventionViewExtended extends Omit<InterventionViewAPI, 'cohort'> {
  sampleSize?: number;
  controlGroup?: string;
  cohort: Cohort;
  sample_info: {
    id: string;
    count: number;
    pid: number;
    cohort_id: CohortAPI;
    group_cnt: Record<string, number>;
    status: string;
    created_at: TimeRFC3999;
  };
  failed: boolean;
  failedMessage: string;
}

export type InterventionId = number;

export enum SplitType {
  FullSplit = 'full',
  TrainSplit = 'train',
  ValidateSplit = 'validate',
  TestSplit = 'test',
  TraitValidateSplit = 'train+validate',
  ValidateTestSplit = 'validate+test',
  TraiValidateTestSplit = 'train+validate+test',
}

export interface TsPoint {
  t: TimeRFC3999;
  v: number;
}

export type GroupTsPoints = Record<string, TsPoint[]>;

export interface BanditMonitorMetrics {
  //  avg_reward: Record<SplitType, GroupTsPoints>;
  //  avg_reward_stddev: ;
  metrics: Record<string, Record<SplitType, GroupTsPoints>>;
  metrics_std: Record<string, Record<SplitType, GroupTsPoints>>;

  split_ratio: GroupTsPoints;
  split_count: GroupTsPoints;
}

export interface ABMonitorMetrics {
  metrics: Record<string, GroupTsPoints>;
}

export interface GroupStateCounter {
  count: number;
  group: GroupName;
  state: number;
}

export type RestlessCounts = Record<TimeRFC3999, GroupStateCounter[]>;

export interface RestlessMonitorMetrics {
  metrics: Record<string, GroupTsPoints>;
  counts: RestlessCounts;
}

export interface MetricData {
  bandit: BanditMonitorMetrics;
  ab: ABMonitorMetrics;
  restless: RestlessMonitorMetrics;
}

export type GroupedSubjects = Record<GroupName, string[]>;

export interface PauseRequest {
  status: boolean;
}
